import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { approvedHostRequest, declineHostRequest, getPendingHost } from "../../store/host/host.action"
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import dayjs from "dayjs";
import Button from "../../extra/Button";
import { FaCheck } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { AcceptProductWarning, AcceptWithdrawRequest } from "../../util/Alert";
import Input from "../../extra/Input";
import { toast } from "react-toastify";
import Male from "../../../assets/images/male.jpg";
import Searching from "../../extra/Searching";
import { approvedWithdrawRequest, declineWithdrawRequest, getPendingWithdrawRequest } from "../../store/withdraw/withdraw.action";
import { CLOSE_DIALOGUE, OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import HostInfo from "../Host/RealHost/HostInfo";
import Info from "../../../assets/images/Info.png"
import WithdrawInfo from "./WithdrawInfo";
import CancelIcon from "@mui/icons-material/Cancel"


const PendingWithdrawReqest = () => {
    const [search, setSearch] = useState("ALL");
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([])
    const { withdrawRequest, total } = useSelector((state) => state.withdrawRequest)
    const [declineModal, setDeclineModal] = useState(false)
    const [type, setType] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const dispatch = useDispatch()
    const [reason, setReason] = useState("")
    const [error, setError] = useState("")
    const [withdrawId, setWithdrawId] = useState("")

    console.log("withdrawId", withdrawId)

    const { dialogue, dialogueType, dialogueData } = useSelector(
        (state) => state.dialogue
    );

    useEffect(() => {
        dispatch(getPendingWithdrawRequest(1))
    }, [dispatch])

    const handleFilterData = useMemo(() => {
        return (filteredData) => {
            if (typeof filteredData === "string") {
                setSearch(filteredData);
            } else {
                setData(filteredData);
            }
        };
    }, [search]);
    useEffect(() => {
        setData(withdrawRequest);
    }, [withdrawRequest]);
    useEffect(() => {
        setCurrentPage(1);
    }, [search]);

    // // pagination
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setCurrentPage(1);
    };

    const handleAccept = (id, e) => {
        e?.prevendDefault();
        try {
            const data = AcceptWithdrawRequest("Accept");

            data.then((isDeleted) => {
                if (isDeleted) {
                    dispatch(approvedWithdrawRequest(id))
                        .then((res) => {
                            toast.success("Withdraw request accepted successfully.");
                        })
                        .catch((error) => toast.error(error.message));
                }
            })

        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = () => {
        if (!reason) {
            setError({ reason: "Reason is required" });
        } else {
            dispatch(declineWithdrawRequest(withdrawId, reason))
                .then((res) => {
                    toast.success("Withdraw request declined successfully.");
                    setDeclineModal(false);
                    setReason("")
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    };

    const handleProfile = (row, type) => {
        dispatch({
            type: OPEN_DIALOGUE,
            payload: {
                type: type,
                data: row,
            },
        });

        let dialogueData_ = {
            dialogue: true,
            type,
            dialogueData: row,
        };

        localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
        localStorage.setItem("realHostInfo", JSON.stringify(dialogueData_));
    };

    const handleInfo = (row, type) => {
        dispatch({
            type: OPEN_DIALOGUE,
            payload: {
                type: type,
                data: row,
            },
        });
    }

    const mapData = [
        {
            Header: "NO",
            width: "20px",
            Cell: ({ index }) => (
                <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
            ),
        },
        {
            Header: "Host",
            body: "profilePic",
            Cell: ({ row }) => (
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleProfile(row, "hostInfo")}
                >
                    <div className="d-flex justify-content-center px-2 py-1">
                        <div>
                            <img
                                src={row?.host?.profilePic || Male}
                                alt=""
                                loading="eager"
                                draggable="false"
                                style={{ borderRadius: "25px" }}
                                height={50}
                                width={50}
                                onError={(e) => e.target.src = Male}
                            />
                        </div>
                        <div className="d-flex flex-column justify-content-center text-start ms-3">
                            <b className="mb-0 text-sm text-capitalize ">{row?.host?.name}</b>
                            <div>
                                <div className="d-flex">
                                    {/* <PlaceIcon
                                        style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
                                    /> */}
                                    <p
                                        className="text-xs text-secondary mb-0  text-capitalize"
                                        style={{ fontSize: "12px" }}
                                    >
                                        {row?.countryId?.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            Header: "Unique Id",
            body: "uniqueId",
            Cell: ({ row }) => (
                <span className="text-success">
                    {row.uniqueId ? row.uniqueId : "-"}
                </span>
            ),
        },
        {
            Header: "Coin",
            body: "coin",
            sorting: { type: "server" },
            Cell: ({ row }) => <span>{row?.coin}</span>,
        },
        {
            Header: "Created At",
            body: "createdAt",
            Cell: ({ row }) => (
                <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
            ),
        },


        {
            Header: "Host",
            body: "",
            Cell: ({ row }) => (
                <img
                    src={Info}
                    height={30}
                    width={30}
                    alt="Info"
                    onClick={() => handleInfo(row, "withdrawInfo")}
                />

            ),
        },

        {
            Header: "Accept",
            body: "",
            Cell: ({ row }) => (
                <>
                    <div onClick={() => {
                        handleAccept(row?._id)
                    }}> <FaCheck /></div>

                </>

            ),
        },
        {
            Header: "Decline",
            body: "",
            Cell: ({ row }) => (
                <>
                    <div style={{ fontSize: "20px" }} onClick={() => {
                        setDeclineModal(true)
                        setWithdrawId(row?._id)
                    }}><MdOutlineCancel /></div>

                </>

            ),
        },
    ];
    return (
        <>
            {dialogue && dialogueType == "hostInfo" && (
                <div className="mainHostInfo">
                    <HostInfo />
                </div>
            )}

            {dialogue && dialogueType == "withdrawInfo" && (
                <div className="mainHostInfo">
                    <WithdrawInfo />
                </div>
            )}
            {
                declineModal && (
                    <div className="mainDialogue fade-in" style={{ zIndex: "9999999" }}>
                        <div
                            className="Dialogue"
                            style={{ overflow: "auto", maxHeight: "100vh", width: "500px" }}
                        >
                            <div className="dialogueHeader">
                                <div className="headerTitle fw-bold">Reason</div>

                                <CancelIcon onClick={() => {
                                    setDeclineModal(false)
                                    setReason("")
                                }} sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />

                            </div>
                            <div
                                className="dialogueMain bg-white"
                                style={{ overflow: "auto", borderRadius: "10px" }}
                            >
                                <div className="col-12">
                                    <Input
                                        label={`Reason`}
                                        placeholder={`Reason`}
                                        id={`reason`}
                                        type={`reason`}
                                        value={reason}
                                        // errorMessage={error.reason && error.reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                            setError({}); // Clear error on input change
                                        }}
                                    />
                                    {
                                        error && (
                                            <div className="text-danger">{error.reason}</div>

                                        )
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end">
                                        <Button
                                            btnName={`Submit`}
                                            btnColor={`btnBlackPrime text-white`}
                                            style={{ borderRadius: "5px", width: "80px" }}
                                            newClass={`me-2`}
                                            onClick={handleSubmit}
                                        />
                                        <Button
                                            btnName={`Close`}
                                            btnColor={`bg-danger text-white`}
                                            style={{ borderRadius: "5px", width: "80px" }}
                                            onClick={() => {
                                                setDeclineModal(false)
                                                setReason("")
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="userHeader primeHeader">
                <Title name={`Pending WithdrawRequest`} />
            </div>
            <div className="primeHeader mt-4 row">
                {/* <div className="d-flex justify-content-end col-md-6">
                    <Searching
                        type={"server"}
                        data={pendingHost}
                        setData={setData}
                        setSearchData={setSearch}
                        onFilterData={handleFilterData}
                        serverSearching={handleFilterData}
                        button={true}
                        column={mapData}
                        placeholder={"Searching Host..."}
                    />
                </div> */}
            </div>
            <div className="userMain">
                <div className="tableMain mt-3">
                    <Table
                        data={data}
                        mapData={mapData}
                        serverPerPage={rowsPerPage}
                        serverPage={currentPage}
                        type={"server"}
                    // onChildValue={handleChildValue}
                    />
                </div>

                <Pagination
                    component="div"
                    count={total}
                    type={"server"}
                    serverPerPage={rowsPerPage}
                    totalData={total}
                    serverPage={currentPage}
                    onPageChange={handleChangePage}
                    setCurrentPage={setCurrentPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    )
}
export default PendingWithdrawReqest