import React, { useMemo } from "react";
import Table from "../../../extra/Table";
import Button from "../../../extra/Button";
import Title from "../../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import dayjs from "dayjs";
import Pagination from "../../../extra/Pagination";
import {
  getHost,
  approvedDisableSwitch,
  blockUnblockSwitch,
} from "../../../store/host/host.action";
import { useNavigate } from "react-router-dom";
import Searching from "../../../extra/Searching";
import { OPEN_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import HostDialog from "./HostDialog";
import HostInfo from "./HostInfo";
import HostHistory from "./History/HostHistory";
import Male from "../../../../assets/images/male.jpg";
import HostImage from "./HostImage";
import HostVideo from "./HostVideo";
import HostChat from "./HostChat";
import EditInfo from "../../../../assets/images/Edit.png";
import ImageInfo from "../../../../assets/images/ImageInfo.png"
import ChatIcon from "../../../../assets/images/Chat.png"
import Info from "../../../../assets/images/Info.png"
import HistoryInfo from "../../../../assets/images/History.png"
import VideoInfo from "../../../../assets/images/videoinfo.png"


const Host = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");
  const [sort, setSort] = useState("createdAt");
  const [type, setType] = useState(0);
  const dispatch = useDispatch();

  const { host, total } = useSelector((state) => state.host);
  console.log("total", total)

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  console.log("currentPage", currentPage)

  useEffect(() => {
    dispatch(
      getHost(
        search === "" ? "ALL" : search,
        currentPage + 1,
        rowsPerPage,
        sort,
        type
      )
    );
  }, [search, currentPage, rowsPerPage, sort, type]);

  useEffect(() => {
    setData(host);
  }, [host]);

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(0);
  };

  const handleClickBlock = (userDetails) => {
    props.blockUnblockSwitch(
      userDetails,
      userDetails?.isBlock === true ? false : true
    );
  };

  const handelSettlement = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const mapData = [
    {
      Header: "NO",
      width: "20px",
      Cell: ({ index }) => (
        <span>{index + 1}</span>
      ),
    },
    {
      Header: "Author",
      body: "profilePic",
      Cell: ({ row }) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleProfile(row, "hostInfo")}
        >
          <div className="d-flex px-2 py-1">
            <div>
              <img
                src={row?.profilePic || Male}
                alt=""
                loading="eager"
                draggable="false"
                style={{ borderRadius: "25px" }}
                height={50}
                width={50}
                onError={(e) => (e.target.src = Male)}
              />
            </div>
            <div className="d-flex flex-column justify-content-center text-start ms-3">
              <b className="mb-0 text-sm text-capitalize ">{row?.name}</b>
              {/* <div>
                <div className="d-flex">
                  <PlaceIcon
                    style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
                  />
                  <p
                    className="text-xs text-secondary mb-0  text-capitalize"
                    style={{ fontSize: "12px" }}
                  >
                    {row?.countryId?.name}
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: "Unique Id",
      body: "uniqueId",
      Cell: ({ row }) => (
        <span className="text-success">
          {row?.uniqueId ? row?.uniqueId : "-"}
        </span>
      ),
    },
    {
      Header: "Mobile Number",
      body: "agencyId",
      Cell: ({ row }) => <span>{row?.mobileNumber}</span>,
    },
    {
      Header: "Agency Name",
      body: "agencyId",
      Cell: ({ row }) => <span>{row?.agencyId?.name}</span>,
    },
    {
      Header: "Gender",
      body: "gender",
      Cell: ({ row }) => <span>{row?.gender ? row?.gender : "Female"}</span>,
    },
    {
      Header: "Coin",
      body: "coin",
      sorting: { type: "server" },
      Cell: ({ row }) => <span>{row?.coin}</span>,
    },
    // {
    //   Header: "Call Rate",
    //   body: "callCharge",
    //   sorting: { type: "server" },
    //   // Cell: ({ row }) => <span>{row?.callCharge}</span>,
    // },
    {
      Header: "Last Settlement Coin",
      body: "lastSettlementCoin",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="text-danger">
          {row?.lastSettlementCoin ? row?.lastSettlementCoin : "0"}
        </span>
      ),
    },
    {
      Header: "Received Coin",
      body: "receiveCoin",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="text-success">
          {row?.receiveCoin ? row?.receiveCoin : 0}
        </span>
      ),
    },
    {
      Header: "Received Gift",
      body: "receiveGift",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="text-primary">{row?.receiveGift}</span>
      ),
    },
    {
      Header: "App Version",
      body: "appVersion",
      Cell: ({ row }) => <span>{row?.appVersion}</span>,
    },
    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Verify Status",
      body: "verifyStatus",
      Cell: ({ row }) => (
        <span className="text-nowrap">
          {row?.status === 1 ? (
            <span style={{ color: "#FBBC05" }}>Pending</span>
          ) : row?.status === 2 ? (
            <span style={{ color: "#198754" }}>Approved</span>
          ) : (
            <span style={{ color: "red" }}>Rejected</span>
          )}
        </span>
      ),
    },
    {
      Header: "Status",
      body: "",
      Cell: ({ row }) =>
        row?.isOnline === true &&
          row?.isLive === true &&
          row?.isBusy === true ? (
          <div
            className="bg-primary py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Live
          </div>
        ) : row?.isOnline === true && row?.isBusy === true ? (
          <div
            className="bgPink py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Busy
          </div>
        ) : row?.isOnline === true ? (
          <div
            className="bg-success py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Online
          </div>
        ) : (
          <div
            className="bg-danger py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Offline
          </div>
        ),
    },
    {
      Header: "Block Host",
      body: "isDisable",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isBlock}
          onClick={() => handleClickBlock(row)}
        />
      ),
    },

    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        // <Button
        //   newClass={` boxCenter userBtn textPink fs-5`}
        //   btnColor={``}
        //   btnIcon={`far fa-edit`}
        //   btnName={"Edit"}
        //   // onClick={() => handleEdit(row)}
        //   style={{
        //     borderRadius: "5px",
        //     margin: "auto",
        //     width: "40px",
        //     fontSize: "10px",
        //   }}

        // />
        <img
          src={EditInfo}
          height={30}
          width={30}
          alt="Edit"
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "Host" },
            });
          }}
        />
      ),
    },
    {
      Header: "Image Info",
      body: "",
      Cell: ({ row }) => (
        // <Button
        //   newClass={`boxCenter userBtn text-primary fs-5`}
        //   btnColor={``}
        //   btnIcon={`fa-solid fa-folder`}
        //   btnName={"Info"}
        //   onClick={() => {
        //     dispatch({
        //       type: OPEN_DIALOGUE,
        //       payload: { data: row, type: "hostImage" },
        //     });
        //   }}
        //   style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
        // />
        <img
          src={ImageInfo}
          height={30}
          width={30}
          alt="Edit"
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "hostImage" },
            });
          }}
        />
      ),
    },

    {
      Header: "Video Info",
      body: "",
      Cell: ({ row }) => (
        <img
          src={VideoInfo}
          height={30}
          width={30}
          alt="Edit"
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "hostVideo" },
            });
          }}
        />
      ),
    },

    {
      Header: "Chat",
      body: "",
      Cell: ({ row }) => (
        <img
          src={ChatIcon}
          height={30}
          width={30}
          alt="Chat"
          onClick={() => handelSettlement(row, "hostChat")}
        />
        // <Button
        //   newClass={` boxCenter userBtn text-success fs-5`}
        //   btnColor={`bg-white`}
        //   btnIcon={`fa-solid fa-history`}
        //   btnName={"Chat"}
        //   style={{ borderRadius: "5px", margin: "auto", width: "40px" }}

        // />
      ),
    },

    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <img
          src={Info}
          height={30}
          width={30}
          alt="Info"
          onClick={() => handleProfile(row, "hostInfo")}
        />

      ),
    },
    {
      Header: "History",
      body: "",
      Cell: ({ row }) => (
        <img
          src={HistoryInfo}
          height={30}
          width={30}
          alt="History"
          onClick={() => handelSettlement(row, "hostHistory")}
        />

      ),
    },

    // add more columns as needed
  ];



  const handleFilterData = useMemo(() => {
    return (filteredData) => {
      if (typeof filteredData === "string") {
        setSearch(filteredData);
      } else {
        setData(filteredData);
      }
    };
  }, []);

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
    localStorage.setItem("realHostInfo", JSON.stringify(dialogueData_));
  };
  
  const handleImage = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });
  };
  const handleChildValue = (value) => {
    setType(type === -1 ? 1 : -1);
    setSort(value);
  };

  return (
    <>
      <div className="mainUserTable  position-relative">
        {dialogue && dialogueType == "hostHistory" && (
          <div className="mainHostInfo">
            <HostHistory />
          </div>
        )}

        {dialogue && dialogueType == "hostChat" && (
          <div className="mainHostInfo">
            <HostChat />
          </div>
        )}

        {dialogue && dialogueType == "hostInfo" && (
          <div className="mainHostInfo">
            <HostInfo />
          </div>
        )}

        <div
          className="userTable "
          style={{
            display: `${dialogueType === "hostInfo"
                ? "none"
                : dialogueType === "hostHistory" || dialogueType === "hostChat"
                  ? "none"
                  : "block"
              }`,
          }}
        >
          <div className="userHeader primeHeader">
            <Title name={`Host`} />
          </div>
          <div className="primeHeader mt-4 row">
            {/* <i className="fas fa-plus fa-md"></i> */}
            <div className="col-md-6 mt-2">
              {/* <Button
                newClass={` border bgsuccess text-white`}
                btnColor={`btnthemePrime`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`New Host`}
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "Host" },
                  });
                }}
                style={{ borderRadius: "7px" }}
              /> */}
              {dialogue && dialogueType === "Host" && <HostDialog />}
              {dialogue && dialogueType === "hostImage" && (
                <HostImage
                  search={search}
                  sort={sort}
                  rowsPerPage={rowsPerPage}
                  currentPage={currentPage}
                  type={type}
                />
              )}
              {dialogue && dialogueType === "hostVideo" && (
                <HostVideo
                  search={search}
                  sort={sort}
                  rowsPerPage={rowsPerPage}
                  currentPage={currentPage}
                  type={type}
                />
              )}
            </div>
            <div className="d-flex justify-content-end col-md-6">
              <Searching
                type={"server"}
                data={host}
                setData={setData}
                setSearchData={setSearch}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                column={mapData}
                placeholder={"Searching Host..."}
              />
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>

            <Pagination
              component="div"
              count={total}
              type={"server"}
              serverPerPage={rowsPerPage}
              totalData={total}
              serverPage={currentPage}
              onPageChange={handleChangePage}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getHost,
  approvedDisableSwitch,
  blockUnblockSwitch,
})(Host);
